import React, { useState } from 'react'
import { Button } from '@components/Basic/Button'
import { BbuRouteParams, PersistentBbuData } from 'LeadsBridgeApp'
import { BbuParnersFullName, BbuPartnersName } from '@app/enums/bridgeByUrl'
import { PersistentLocalStorageKeys } from '@app/enums/persistentLocalStorageKeys'
import { appRoutes } from '@app/routes'
import { ButtonProvider } from '@components/ButtonProvider'
import { Loader } from '@components/Basic/Loader'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { identifyUserByUserData, trackEvent } from '@app/dataTracking'
import { useAppSelector } from '@app/store/hooks'
import { AuthState } from '@aws-amplify/ui-components'

interface Props {
  partnerName: BbuPartnersName
  integrationsMeta: PersistentBbuData
}

export const PartnerConnectButton: React.FC<Props> = ({
  partnerName,
  integrationsMeta,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const urlParams = useParams<BbuRouteParams>()
  const [isDoingLogin, setIsDoingLogin] = useState(false)

  const authState = useAppSelector((state) => state.user.authState)
  const userAuthState = useAppSelector((state) => state.user.auth)
  const userIsLogged = authState === AuthState.SignedIn && userAuthState

  const onTikTokAuthRequest = () => {
    setIsDoingLogin(true)

    // save source+destination+requestUri in session storage, so it will be persistent
    // (will resist to page refresh on same tab) and we will still allow user to complete
    // bbu flow by opening multiple tabs. Each tab will have its own persistent data
    window.sessionStorage.setItem(
      PersistentLocalStorageKeys.bbuPersistentData,
      JSON.stringify(integrationsMeta)
    )

    // after auth flow, tiktok will redirect user to https://leadsbridge.com/app/tiktokAccount/new?state=bounce_to_evo_production&auth_code=xxxxxxxxxxx&code=xxxxxxxx'
    // v1 will read state param and redirect back to our app https://app.leadsbridge.com/auth/tt/callback?lb_code=xxxxxxxxx
    const urlParams = [
      `app_id=${process.env.REACT_OAUTH_TIKTOK_APP_ID}`,
      `state=${process.env.REACT_OAUTH_TIKTOK_STATE}`,
      `redirect_uri=${encodeURIComponent(
        process.env.REACT_OAUTH_TIKTOK_REDIRECT_URI || ''
      )}`,
      'rid=kb5sq2ywj9',
    ]
    const tikTokUrl =
      `https://ads.tiktok.com/marketing_api/auth?` + urlParams.join('&')

    if (process.env.REACT_USE_MOCK_API === 'true') {
      window.location.href =
        window.location.origin +
        appRoutes.bridgeByUrlCallback.makeUrl({
          partnerFullName: BbuParnersFullName.tiktok,
        }) +
        '?lb_code=12323'
    } else {
      window.location.href = tikTokUrl
    }
  }

  switch (partnerName) {
    case BbuPartnersName.facebook:
    case BbuPartnersName.facebookConversionLeads:
      return (
        <div style={{ position: 'relative' }}>
          {isDoingLogin && <Loader $active $dimmer />}
          <ButtonProvider
            provider={'Facebook'}
            scope={'NoScope'}
            onBeforeAuth={() => {
              setIsDoingLogin(true)
            }}
            onAuthError={(_, errorCode) => {
              console.log(errorCode)
              if (
                errorCode === 'auth-provider-email-not-received' &&
                !userIsLogged
              ) {
                // redirect user to new app survey
                history.push(
                  appRoutes.signupBbuFallbackSurvey.makeUrl({
                    partnerName: partnerName,
                    source: urlParams.source,
                    destination: urlParams.destination,
                  })
                )
              }
              setIsDoingLogin(false)
            }}
            onAuthSuccess={async (userInfo) => {
              // auth success
              console.log('auth ', userInfo)

              // we wait to have the user identified before sending the track event
              await identifyUserByUserData(userInfo).catch(() => {
                console.log('not identified - segment not active')
              })

              trackEvent({
                eventName: userInfo.newUser ? 'SignUp' : 'SignIn',
                feature: 'Authentication',
                step: userInfo.newUser ? 'Signup' : 'Signin',
                params: {
                  custom: {
                    planId: userInfo.pricing.cbPlanId,
                    mrr: (userInfo?.pricing?.mrr || 0) / 100,
                    channel: partnerName,
                    sourceId: integrationsMeta?.source?.id,
                    sourceName: integrationsMeta?.source?.name,
                    destinationId: integrationsMeta?.destination?.id,
                    destinationName: integrationsMeta?.destination?.name,
                  },
                },
              })
            }}
          />
        </div>
      )
    case BbuPartnersName.tiktok:
      return (
        <Button
          $fluid
          $variant={'tiktok'}
          onClick={onTikTokAuthRequest}
          $loading={isDoingLogin}
          $size={'large'}
          disabled={isDoingLogin}
        >
          {t('bbu.ctaTikTok')}
        </Button>
      )
    case BbuPartnersName.app:
      // this is for nbee bbu, we don't need to display any connect button
      return null
    default:
      return <div>Partner not valid</div>
  }
}
